import { navigate } from 'gatsby-plugin-react-intl'
import React, { useEffect } from 'react'

import { routes } from '../routes'

const IndexPage = () => {
  useEffect(() => {
    navigate(routes.studies, { replace: true })
  }, [])

  return <div />
}

export default IndexPage
export const Head = () => <title>Datacapt - Home</title>
